import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import PageLayout from '../components/PageLayout'
import RichText from 'prismic-reactjs/src/Component'
import clsx from 'clsx'
import ResolvingLink from '../components/ResolvingLink'
import SEO from '../components/seo'
import Image from 'gatsby-image'
import getFixedImg from '../utils/getFixedImg'
import genericHtmlSerializer from '../utils/genericHtmlSerializer'

export const query = graphql`
  query GenericPageQuery($uid: String, $lang: String) {
    prismicGenericPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        body {
          ... on PrismicGenericPageBodyTitle {
            id
            slice_label
            slice_type
            primary {
              title {
                text
              }
            }
          }
          ... on PrismicGenericPageBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicGenericPageBodyImageBesideText {
            id
            slice_type
            slice_label
            primary {
              button_link {
                url
                target
                link_type
              }
              button_text {
                text
              }
              is_reversed
              text {
                raw
              }
              delivery_section_title {
                text
              }
            }
            items {
              delivery_image {
                alt
                localFile {
                  childImageSharp {
                    fixed(height: 25) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              delivery_link {
                url
                target
                link_type
              }
            }
          }
        }
      }
    }
    prismicNavigationBar(lang: { eq: $lang }) {
      ...NavigationBarFragment
    }
    prismicLeftNavigationMenu(lang: { eq: $lang }) {
      ...LeftNavigationMenuFragment
    }
    prismicRightNavigationMenu(lang: { eq: $lang }) {
      ...RightNavigationMenuFragment
    }
    prismicStayConnectedSection(lang: { eq: $lang }) {
      ...StayConnectedSectionFragment
    }
    prismicBottomNavigationBar(lang: { eq: $lang }) {
      ...BottomNavigationBarFragment
    }
    prismicCookieBanner(lang: { eq: $lang }) {
      ...CookieBannerFragment
    }
  }
`

const PageSlices = function PageSlices({ slices }) {
  if (slices == null || slices.length === 0) {
    return []
  }

  const renderedSlices = slices.map((slice, idx) => {
    const { slice_type: sliceType, id } = slice
    const isNotFirst = idx !== 0

    switch (sliceType) {
      case 'title':
        const {
          primary: {
            title: { text: title },
          },
        } = slice

        return (
          <section
            key={id}
            className={clsx('container px-4 md:mx-auto', isNotFirst && 'mt-12')}
          >
            <h1 className="text-gray-title">{title}</h1>
          </section>
        )
      case 'text':
        const {
          primary: {
            text: { raw: richText },
          },
        } = slice

        if (richText == null || richText.length === 0) {
          return null
        }

        return (
          <section
            key={id}
            className={clsx('container px-4 md:mx-auto', isNotFirst && 'mt-12')}
          >
            <RichText render={richText} htmlSerializer={genericHtmlSerializer} />
          </section>
        )
      case 'image_beside_text':
        const {
          primary: {
            button_text: { text: buttonText },
            button_link: buttonLink,
            is_reversed: isReversed,
            text: { raw: contactRichText },
            delivery_section_title: { text: deliverySectionTitle },
          },
          items,
        } = slice

        const isDeliveries =
          deliverySectionTitle && items != null && items.length !== 0

        const contactDescriptionSectionClassName = clsx(
          'flex flex-col items-center text-gray-body text-center md:w-2/5 md:items-start md:text-left',
          isReversed ? 'md:pr-4' : 'md:pl-4'
        )

        return (
          <section
            key={id}
            className={clsx(
              'h-screen container mx-auto px-4 flex items-center',
              isReversed
                ? 'flex-col-reverse md:flex-row-reverse'
                : 'flex-col md:flex-row',
              isNotFirst && 'mt-12'
            )}
          >
            <iframe
              className="mt-4 h-full md:w-3/5 md:mt-0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2571.8729605470207!2d-119.55779268411035!3d49.86363097939877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537df52d8e6e68ed%3A0xfcec4dbb1f2e9fc!2sGrizzli%20Winery!5e0!3m2!1sen!2sca!4v1608887525221!5m2!1sen!2sca"
              frameBorder="0"
              style={{ border: '0' }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
            <div className={contactDescriptionSectionClassName}>
              <RichText render={contactRichText} />
              {buttonText != null && buttonText !== '' && (
                <ResolvingLink
                  link={buttonLink}
                  className="btn btn-yellow-outline mt-6 uppercase"
                >
                  {buttonText}
                </ResolvingLink>
              )}
              {isDeliveries && (
                <div className="mt-10">
                  <h2>{deliverySectionTitle}</h2>
                  <div className="mt-6 flex flex-wrap items-center gap-x-6 gap-y-2">
                    {_.compact(
                      items.map((deliveryItem, idx) => {
                        const deliveryImg = getFixedImg(
                          deliveryItem.delivery_image
                        )

                        const deliveryImgAlt = _.get(
                          deliveryItem,
                          'delivery_image.alt'
                        )

                        const deliveryLink = deliveryItem.delivery_link

                        if (deliveryImg == null || deliveryLink === null) {
                          return null
                        }

                        return (
                          <ResolvingLink key={idx} link={deliveryLink}>
                            <Image fixed={deliveryImg} alt={deliveryImgAlt} />
                          </ResolvingLink>
                        )
                      })
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        )
      default:
        return null
    }
  })

  return _.compact(renderedSlices)
}

export default function GenericPage({ data }) {
  const { prismicGenericPage: singlePage } = data

  if (
    singlePage == null ||
    singlePage.data == null ||
    singlePage.data.body == null
  ) {
    // TODO - Generate 404
    return null
  }

  const pageTitle = _.get(singlePage, 'data.page_title.text')
  const pageDescription = _.get(singlePage, 'data.page_description.text')

  return (
    <PageLayout pageData={data}>
      <SEO title={pageTitle} description={pageDescription} />
      <div className="min-h-screen py-general page-general">
        <PageSlices slices={singlePage.data.body} />
      </div>
    </PageLayout>
  )
}
